import React, { useEffect, useRef, useState } from "react";
import { MdArrowRight, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import profile from "../../assests/Wincha Profile Icon.png";
import style from "./Profile.module.css";
import rightArrow from "../../assests/Enter Edit Arrow.png";
import shippingInfo from "../../assests/Subscription Info Icon.png";
import myDetailsBlue from "../../assests/My Details Selected Tab.png";
import shippingGray from "../../assests/Shipping Unselected Tab.png";
import myDetailsGray from "../../assests/My Details Unselected Tab.png";
import shippingBlue from "../../assests/Shipping Selected Tab.png";
// import { configutation } from "../../actions/product";
import { Link, useNavigate } from "react-router-dom";
import { assets } from "../Description/assests";
import eye from "../../assests/Password Eye.png";
import Loader from "../NewLoader/NewLoader";
import info from "../../assests/Information Icon.png";
import icon from "../../assests/Wincha Support Icon.png";
import BundleSection from "../../assests/Prize Bundling_300dpi.png";
import FreeplaySection from "../../assests/Unlimited Freeplays_300dpi.png";
import NotificationSection from "../../assests/Exclusive Prizes Icon and Text_300dpi.png";
import ShippingSection from "../../assests/Free Next Day Delivery_300dpi.png";
import CloseImage from "../../assests/Artboard 48 X.png";
import Lower from "../../assests/Artboard 48 - Lower Image Split.png";
import Upper from "../../assests/Artboard 48 - Upper Image Split.png";
import Lottie from "lottie-react";
import { updateProfile } from "../../actions/user";
import { FaChevronDown } from "react-icons/fa";
import { AllAnimation } from "../../Animation/allAnimation";
import { music } from "../../assests/Musics/allMusic";
import { baseUrl } from "../url";
import PlayAudio from "../Audio/PlayAudio";
import { encrypt } from "../EncryptionUtils";
const Profile = ({ gameMusic, setGameMusic, gameSound, setGameSound }) => {
  const navigate = useNavigate();

  const token = JSON.parse(localStorage.getItem("token"));
  const deviceId = JSON.parse(localStorage.getItem("deviceId"));


  // const baseUrl = "https://uat.wincha-online.com"
  // const baseUrl = "https://uat.wincha-online.com"

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [premiumPopup, setPremiumPopup] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [subscription, setSubscription] = useState("");
  const [birthday, setBirthday] = useState("");
  const [myDetails, setmyDetails] = useState(true);
  const { user } = useSelector((state) => state.profile);
  const { configuration } = useSelector((state) => state.configuration);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [Deactivate, setDeactivate] = useState(false);
  const [resendEmail, setResendEmail] = useState(
    user && user.profile_status === false ? true : false
  );
  const [message, setMessage] = useState(false);
  const [type, setType] = useState(true);
  const [isAddress, setIsAddress] = useState(false);
  const [line1, setLine1] = useState(user?.addressline1 || "");
  const [line2, setLine2] = useState(user?.addressline2 || "");
  const [city, setCity] = useState(user?.city || "");
  const [state, setState] = useState(user?.state || "");
  const [zipcode, setZipCode] = useState(user?.zipcode || "");
  const [number, setNumber] = useState(user?.phone || "");
  const [firstName, setFirstName] = useState(user?.first_name || "");
  const [lastName, setLastName] = useState(user?.last_name || "");
  const [selectState, setSelectState] = useState(false);
  const [allState, setAllState] = useState([]);
  const [postcodetrue, setPostcodeTrue] = useState(false);
  const [phonenumber, setPhonenumber] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [passwordCheckError, setPasswordCheckError] = useState("");
  const [userExist, setUserExist] = useState(false);


  const [addressObj, setAddressObj] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipcode: "",
  });
  const dispatch = useDispatch();
  // const userId = JSON.parse(localStorage.getItem("user"));
  const userId =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  console.log(userId);
  const newBaseKey = localStorage.getItem("baseKey");
  const newBaseIv = localStorage.getItem("baseIv");
  const [passIcon, setPassIcon] = useState(false);
  const [checkMail, setCheckMail] = useState(false);
  const [accountSuspend, setAccountSuspend] = useState(false);

  const resendLocal = localStorage.getItem("resend");

  async function deactivateAccount() {
    await fetch(`${baseUrl}/game/webapp/issue/report `, {
      method: "POST",
      body: JSON.stringify({
        playerID: userId,
        machineID: "", // send it empty
        productID: "", // send it empty
        title: "deactivation",
        content: message, // reason of deactivation
        source: "web",
      }),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // setVipData(data);
        console.log(data);
        // e.preventDefault();
        // console.log(window.location.pathname.split("/"))
        // navigate("/");

        //
        localStorage.removeItem("user");
        localStorage.removeItem("SaveShipping");
        localStorage.removeItem("times");
        window.location.reload();
        //

        // navigate("/")
      });
  }

  useEffect(() => {
    if (user) {
      // const date = user.dob.split("-");
      // console.log(date)
      setUsername(user.username);
      setEmail(user.email);
      // setPassword()
      // setBirthday(`${date[2]}-${date[1]}-${date[0]}`)
      setBirthday(user.dob);
      // console.log(birthday)
      setSubscription(user.vip ? "On" : "Off");
    }
  });
  useEffect(() => {
    // dispatch(configuration());
    console.log(newBaseIv);
    console.log(newBaseKey);
    console.log(configuration.ANDROID_VERSION);
    console.log(configuration);
  }, []);

  // useEffect(() => {
  //   if (user && user.profile_status === false) {
  //     setResendEmail(true);
  //   }
  // }, []);
  useEffect(() => {
    console.log(resendLocal, "resendLocal");
    // const resendLocal = localStorage.getItem("resend")
    if (resendLocal === true) {
      setResendEmail(false);
      setCheckMail(true);
    }
  }, [resendLocal, user]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  async function resendEmailApi() {
    setLoading(true);
    await fetch(`${baseUrl}/user/webapp/verification/resend`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
        source: "web",
      }),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        localStorage.setItem("resend", true);
        setLoading(false);
        setResendEmail(false);
        setCheckMail(true);
      });
  }

  const passwordValidation = (e) => {
    e.preventDefault();
    if (password.length < 8 || password.length > 20) {
      setPasswordCheckError("Invalid password length. Please enter between 8 and 20 characters.");
    } else if (!/[A-Z]/.test(password)) {
      setPasswordCheckError("Invalid password. Include at least 1 capital letter.");
    } else if (!/\d/.test(password)) {
      setPasswordCheckError("Invalid password. Include at least 1 number.");
    } else if (!/(.*[!@#$%&*()'+,./:;<=>?^_`{|}-])/.test(password)) {
      setPasswordCheckError("Invalid password. Include at least 1 special character.");
    } else if (!/^\S+$/.test(password)) {
      setPasswordCheckError("Invalid password. No spaces allowed.");
    } else if (password === "") {
      setPasswordCheckError("Password should not be empty.");
    } else if (password !== confirmPassword) {
      setPasswordCheckError("Passwords do not match.");
    }
    else {
      setUpdatePassword(false);
      updatePasswordFunc()
    }
  }

  async function updatePasswordFunc(e) {

    const bodyData = {
      id: userId,
      username: user.username,
      password: password,
      source: "web",
    };
    console.log(bodyData);
    const newData = JSON.stringify(bodyData);
    const plaintext = newData;
    // const key = "ocpwincha4tR5d0P";
    // const iv = "ocpwincha7h3XrYb";
    const key = newBaseKey;
    const iv = newBaseIv;
    console.log(newBaseKey);
    console.log(newBaseIv);

    const encryptedText = encrypt(plaintext, key, iv);
    console.log(encryptedText, "encrypted_text");
    const newBody = {
      title: encryptedText,
      source: "web",
      type: "web",
    };
    console.log(newBody);
    await fetch(`${baseUrl}/user/profile/update`, {
      method: "PUT",
      body: JSON.stringify(newBody),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // localStorage.setItem("resend",true)
        // setLoading(false)
        // setResendEmail(false)
        // setCheckMail(true)
        console.log(data);
        setPassword("")
        setConfirmPassword("")

      });
  }
  async function vipPayment() {
    localStorage.setItem("navLink", "profile")
    const requestData = {
      mode: "subscription",
      subscriptionPriceId: configuration.STRIPE_SUBSRIPTION_ID,
      amount: parseFloat(configuration.VIP_SUBSCRIPTION).toFixed(2) * 100,
      quantity: 1,
      success_url: `${window.location.origin}/payment/success/?session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${window.location.origin}/profile/?session_id={CHECKOUT_SESSION_ID}`,
      // "currency":"inr",
      currency: configuration.CURRENCY_CODE,
      product: "vip",
      payment_mode: "vip",
      user_id: userId,
      credict_point: `${configuration.VIP_BONUS_POINT}`,
    };
    await fetch(`${baseUrl}/points/webapp/create-checkout-session `, {
      method: "POST",
      body: JSON.stringify(requestData),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        window.location.assign(`${data.data[0].url}`);
      });
  }
  // const checkStateExits = (state, e) => {
  //   e.preventDefault();
  //   if (state.status === false) {
  //      setCheckError(true);
  //     setState("");
  //     setSelectState(false);
  //   } else {
  //     setState(state);
  //     setSelectState(false);
  //   }
  // };
  async function numberValidation() {
    await fetch(`${baseUrl}/user/webapp/phonecode/check`, {
      method: "POST",
      body: JSON.stringify({
        user: userId,
        number: `${configuration.COUNTRY_CODE}${number}`,
      }),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.data[0].valid === true) {
          postCodeCheck();
        } else {
          setPhonenumber(true);
        }
      });
  }
  async function postCodeCheck() {
    await fetch(`${baseUrl}/configurations/webapp/code/check `, {
      method: "POST",
      body: JSON.stringify({
        country: "UK",
        code: zipcode,
      }),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "True") {
          addAddress();
        } else {
          setPostcodeTrue(true);
        }
        console.log(data);
      });
  }

  async function checkFreePlay() {
    const userBody = {
      // user: user&&user.username===""?"":userId,
      user: userId === "" ? "" : userId,
      device_id: deviceId,
      source: "web",
      type: "web",
    };
    await fetch(`${baseUrl}/game/webapp/freeplay/limit`, {
      method: "POST",
      body: JSON.stringify(userBody),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {

        if (data.data[0].account_suspension === true) {
          setAccountSuspend(true)
        }
        if (data.data[0].account_exists === false) {
          setUserExist(true)
        }

      });
  }
  async function addAddress() {
    // if (configuration.COUNTRY_CODE === "44") {
    //   setUserCountry("county");
    // } else {
    //   setUserCountry("state");
    // }
    const body = {
      user_id: userId,
      username: user.username,
      first_name: firstName,
      last_name: lastName,
      phone: number,
      addressline1: line1,
      addressline2: line2,
      city: city,
      state: state,
      zipcode: zipcode,
      coutrycode: configuration.COUNTRY_CODE,
      coutryname: configuration.COUNTRY_NAME,
    };
    if (configuration.COUNTRY_CODE === "1") {
      body = {
        user_id: userId,
        username: user.username,
        first_name: firstName,
        last_name: lastName,
        phone: `${number}`,
        addressline1: line1,
        addressline2: line2,
        city: city,
        state: state,
        zipcode: zipcode,
        coutrycode: configuration.COUNTRY_CODE,
        coutryname: configuration.COUNTRY_NAME,
      };
    }
    const newData = JSON.stringify(body);
    const plaintext = newData;
    // const key = "ocpwincha4tR5d0P";
    // const iv = "ocpwincha7h3XrYb";
    const key = newBaseKey;
    const iv = newBaseIv;
    console.log(newBaseKey);
    console.log(newBaseIv);

    const encryptedText = encrypt(plaintext, key, iv);
    console.log(encryptedText, "encrypted_text");
    const newBody = {
      title: encryptedText,
      source: "web",
      type: "web",
    }
    await fetch(`${baseUrl}/user/webapp/shipping/details/update`, {
      method: "PUT",
      body: JSON.stringify(newBody),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        dispatch(updateProfile());
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const stateFetch = () => {
    fetch(`${baseUrl}/configurations/state/collections`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAllState(data.data);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    stateFetch();
  }, []);
  const checkStateExits = (state, e) => {
    e.preventDefault();
    if (state.status === false) {
      setCheckError(true);
      setState("");
      setSelectState(false);
    } else {
      setState(state);
      console.log();
      setSelectState(false);
    }
  };

  const handleInputClick = () => {
    if (userId === null || user.username === "") {
      navigate("/login");
      console.log(user.username, "profileuser");
    }
  };

  useEffect(() => {
    if (userId && deviceId) {
      checkFreePlay();

    }
  }, [userId, deviceId]);

  return (
    <div className={style.Container}>
      {/* <audio ref={audioRefHome} onEnded={audioEnded} loop></audio> */}
      <PlayAudio
        gameMusic={gameMusic}
        setGameMusic={setGameMusic}
        gameSound={gameSound}
        setGameSound={setGameSound}
      />

      {loading ? (
        <div className={style.LoaderDiv}>
          <div className={style.LoaderAnime}>
            <Lottie animationData={AllAnimation.Loader} />
          </div>
        </div>
      ) : (
        <div
          className={style.Profile}
          onClick={() => {
            console.log(resendLocal);
            console.log(
              (user &&
                user.profile_status === false &&
                checkMail === false &&
                resendLocal === false) ||
              resendLocal === undefined,
              "chekcing intense"
            );
            if (resendLocal === true) {
              setResendEmail(false);

              setCheckMail(true);
            }
            if (
              user &&
              user.profile_status === false &&
              checkMail === false &&
              resendLocal !== true
            ) {
              setResendEmail(true);
              console.log(resendLocal, "true");
            } else {
              setResendEmail(false);
            }
          }}
        >
          {premiumPopup ? (
            <div className={style.clubHousePopup}>
              <div
                className={style.OverlayBg}
                onClick={() => {
                  setPremiumPopup(false);
                }}
              ></div>
              <div className={style.ClubHouse}>
                <div className={style.TopImage}>
                  <div
                    className={style.clubHouseClose}
                    onClick={() => {
                      setPremiumPopup(false);
                    }}
                  >
                    {/* <MdClose/> */}
                    <img src={CloseImage} alt="" />
                  </div>
                  <img src={Upper} alt="" />
                </div>
                <div className={style.BottomContents}>
                  {/* <div className={style.LowerImg}>
                <img src={Lower} alt="" />
              </div> */}
                  <div className={style.BonusPoints}>
                    <div className={style.Bonus}>
                      <p>{configuration.VIP_BONUS_POINT}W</p>
                    </div>
                    <div className={style.BonusText}>
                      <p>Sign Up Bonus!</p>
                    </div>
                  </div>
                  <div className={style.benefits}>
                    <div className={style.benefit}>
                      <div className={style.benefitImage}>
                        <img src={ShippingSection} alt="" />
                      </div>
                    </div>
                    <div className={style.benefit}>
                      <div className={style.benefitImage}>
                        <img src={BundleSection} alt="" />
                      </div>
                    </div>
                    <div className={style.benefit}>
                      <div className={style.benefitImage}>
                        <img src={NotificationSection} alt="" />
                      </div>
                    </div>
                    <div className={style.benefit}>
                      <div className={style.benefitImage}>
                        <img src={FreeplaySection} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className={style.SubscribeButton}>
                    <button
                      onClick={() => {
                        if (
                          (user && user.username === "") ||
                          user.username === undefined
                        ) {
                          return navigate("/login");
                        } else {
                          vipPayment();
                        }
                      }}
                    >{`${configuration.CURRENCY_SYMBOL}${configuration.VIP_SUBSCRIPTION} / ${configuration.VIP_SUBSCRIPTION_PERIOD}`}</button>
                  </div>
                  <div className={style.CancelSubscription}>
                    <p>Cancel any time</p>
                  </div>
                </div>
                <div className={style.TermsAndPolicy}>
                  <div
                    className={style.Terms}
                    onClick={() => {
                      window.open(`${configuration.terms}`, "_blank");
                    }}
                  >
                    <p>Subscription Terms</p>
                  </div>
                  <div
                    className={style.Policy}
                    onClick={() => {
                      window.open(`${configuration.privacy}`, "_blank");
                    }}
                  >
                    <p>Privacy Policy</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {passIcon && userId !== null && user && user.username !== "" ? (
            <div className={style.Passpopup}>
              <div
                className={style.OverlayBg}
                onClick={() => {
                  setPassIcon(false);
                }}
              ></div>
              <div className={style.image}>
                <img src={icon} alt="" />
              </div>
              <div className={style.content}>
                <ul>
                  <p>Password must include:</p>
                  <li>8-20 Characters</li>
                  <li>At least 1 capital letter</li>
                  <li>At least 1 number</li>
                  <li>At least 1 special character</li>
                  <li>No spaces</li>
                </ul>
              </div>
              <div className={style.action}>
                <button
                  onClick={(e) => {
                    setPassIcon(false);
                    // forgotPass?forgotPassword(e):setPassIcon(false)
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          ) : (
            ""
          )}

          {updatePassword && userId !== null && user && user.username !== "" ? (
            <div className={style.AddressSection}>
              <div
                className={style.OverlayBg}
                onClick={() => {
                  setUpdatePassword(false);
                }}
              ></div>
              <form>

                <input
                  type="text"
                  name=""
                  id=""
                  // value={line1}
                  readOnly
                  value={user.username}
                  placeholder="USERNAME"
                  onChange={(e) => {
                    // setLine1(e.target.value);
                    //  line2 = e.target.value
                  }}
                />

                <div className={style.email}>
                  <div className={style.info}>
                    <img
                      src={info}
                      alt=""
                      onClick={() => {
                        setPassIcon(true);
                      }}
                    />
                  </div>
                  {/* <label htmlFor="password">Password</label> */}
                  <input
                    type={type ? "password" : "text"}
                    name=""
                    id="password"
                    autocomplete="off"
                    className={style.passwordInput}
                    value={password}
                    placeholder="NEW PASSWORD"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordCheckError("")

                    }}

                  />
                  <div className={style.eye}>
                    <img
                      src={eye}
                      alt=""
                      onClick={() => {
                        type ? setType(false) : setType(true);
                      }}
                    />
                  </div>
                </div>
                {passwordCheck ? (
                  password.length < 8 || password.length > 20 ? (
                    <p className={style.AgeRestrict}>
                      Password does not match criteria
                    </p>
                  ) : /\d/.test(password) === false && password !== "" ? (
                    <p className={style.AgeRestrict}>
                      Password does not match criteria
                      {/* digit */}
                    </p>
                  ) : /[A-Z]/.test(password) === false && password !== "" ? (
                    <p className={style.AgeRestrict}>
                      Password does not match criteria
                      {/* upper case */}
                    </p>
                  ) : /(.*[!@#$%&*()'+,./:;<=>?^_`{|}-])/.test(password) === false && password !== ""
                    ? (
                      <p className={style.AgeRestrict}>
                        Password does not match criteria
                        {/* special charecter */}
                      </p>
                    ) : /^\S+$/.test(password)
                      === false && password !== ""
                      ? (
                        <p className={style.AgeRestrict}>
                          Password does not match criteria
                          {/* white spaces */}
                        </p>
                      ) : (
                        ""
                      )
                ) : (
                  ""
                )}
                <input
                  type="text"
                  name=""
                  value={confirmPassword}
                  id=""
                  autocomplete="off"
                  // value={number}
                  placeholder="REPEAT NEW PASSWORD"
                  onClick={() => {
                    if (password.length > 0) {
                      setPasswordCheck(true);
                      setPasswordCheckError("")
                    }
                  }}
                  onChange={(e) => {
                    // setNumber(e.target.value)
                    //  line2 = e.target.value
                    setConfirmPassword(e.target.value)
                  }}
                />
                <input
                  className={style.emailip}
                  type="email"
                  name=""
                  id=""
                  // value={city}
                  value={user.email}
                  readOnly
                  placeholder="EMAIL"
                  onChange={(e) => {
                    // setCity(e.target.value)
                    //  line2 = e.target.value
                  }}
                />
                {passwordCheckError && <p className={style.AgeRestrict}>{passwordCheckError}</p>}
                <button

                  onClick={(e) => {
                    passwordValidation(e)
                  }}
                // disabled={ line1===""|| line2===""|| city===""|| state===""|| zipcode===""}
                >
                  CONFIRM
                </button>
              </form>
            </div>
          ) : (
            ""
          )}
          {Deactivate && userId !== null && user && user.username !== "" ? (
            <div className={style.DeactivatePopup}>
              <div
                className={style.DeacOverlayBg}
                onClick={() => {
                  setDeactivate(false);
                }}
              ></div>
              <div className={style.popup}>
                <div className={style.popupImage}>
                  <img src={assets.winchaPopup} alt="" />
                </div>
                <div className={style.ReportPopupButtonCategory}>
                  {/* <button>DEACTIVATE</button> */}
                </div>
                <div className={style.popupText}>
                  <p>
                    If you proceed with an account deletion,your account will be
                    irreversibly deleted resulting in your data and any
                    remaining credit being permanently and immediately erased.
                    Please remember to also turn off any in-app subscriptions in
                    your device account settings that are associated with this
                    app.
                  </p>
                </div>
                {/* <div className={style.popupInput}>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                placeholder="If you can, please tell us why you're leaving..."
              ></textarea>
            </div> */}
                <div></div>
                <div className={style.popupSubmit}>
                  <div className={style.cancelButton}>
                    <button
                      onClick={() => {
                        setDeactivate(false);
                      }}
                    >
                      CLOSE
                    </button>
                  </div>
                  <div className={style.deleteButton}>
                    <button
                      onClick={() => {
                        // sendReport();
                        deactivateAccount();
                      }}
                    >
                      DELETE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {resendEmail && userId !== null && user && user.username !== "" ? (
            <div className={style.resendEmailpopup}>
              <div
                className={style.popupOverlay}
                onClick={() => {
                  if (resendLocal === true) {
                    setResendEmail(false);

                    setCheckMail(true);
                  }
                  // setResendEmail(false)
                }}
              ></div>
              <div className={style.popupContent}>
                <div className={style.popupImage}>
                  <img src={assets.winchaPopup} alt="" />
                </div>
                <div className={style.resendpopupText}>
                  <p>Awaiting player verification</p>
                </div>
                <div className={style.popupButton}>
                  <div
                    // to="/tickets"
                    onClick={() => {
                      // setResendEmail(false);
                      // resendEmailApi()
                    }}
                  >
                    <button
                      onClick={() => {
                        // setResendEmail(false);
                        resendEmailApi();
                      }}
                    >
                      RESEND EMAIL
                    </button>
                  </div>
                </div>
              </div>
              {/* {loading?
          <Lottie animationData={AllAnimation.Loader}/>
          :""} */}
            </div>
          ) : (
            ""
          )}
          {checkMail && userId !== null && user && user.username !== "" ? (
            <div className={style.resendEmailpopup}>
              <div
                className={style.popupOverlay}
                onClick={() => {
                  // setResendEmail(false)
                }}
              ></div>
              <div className={style.popupContent}>
                <div className={style.popupImage}>
                  <img src={assets.winchaPopup} alt="" />
                </div>
                <div className={style.popupText}>
                  <p>Please check your mail and refresh the page</p>
                </div>
                <div className={style.popupButton}>
                  <div
                    // to="/tickets"
                    onClick={() => {
                      // setResendEmail(false);
                      // resendEmailApi()
                    }}
                  >
                    {/* <button  onClick={() => {
                  resendEmailApi();
                }}>RESEND EMAIL</button> */}
                  </div>
                </div>
              </div>
              {/* {loading?
          <Lottie animationData={AllAnimation.Loader}/>
          :""} */}
            </div>
          ) : (
            ""
          )}
          {isAddress && userId !== null && user && user.username !== "" ? (
            <div className={style.editAddress}>
              <div
                className={style.editAddressOverlay}
                onClick={() => {
                  setIsAddress(false);
                  setSelectState(false);
                }}
              ></div>
              <form>
                <h1>Shipping Address</h1>
                <input
                  type="text"
                  name=""
                  id=""
                  value={firstName}
                  placeholder="FIRST NAME"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
                <input
                  type="text"
                  name=""
                  id=""
                  value={lastName}
                  placeholder="LAST NAME"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
                <input
                  type="text"
                  name=""
                  id=""
                  value={line1}
                  placeholder="LINE 1"
                  onChange={(e) => {
                    setLine1(e.target.value);
                  }}
                />
                <input
                  type="text"
                  name=""
                  id=""
                  value={line2}
                  placeholder="LINE 2"
                  onChange={(e) => {
                    setLine2(e.target.value);
                  }}
                />
                <input
                  type="text"
                  name=""
                  id=""
                  value={number}
                  placeholder="PHONE NUMBER"
                  onChange={(e) => {
                    setNumber(e.target.value);
                  }}
                />
                <input
                  type="text"
                  name=""
                  id=""
                  value={city}
                  placeholder="CITY"
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
                {user.coutrycode === "1" ? (
                  <div className={`${style.input} ${style.selectInput}`}>
                    {state.state ? (
                      <input
                        type="text"
                        readOnly
                        value={state.state}
                        className={style.StateSelect}
                      />
                    ) : (
                      <input
                        type="text"
                        readOnly
                        className={style.StateSelectCenter}
                        placeholder="SELECT STATE"
                      />
                    )}
                    { }
                    <FaChevronDown
                      className={style.Downarrow}
                      onClick={() => {
                        selectState
                          ? setSelectState(false)
                          : setSelectState(true);
                      }}
                    />
                    {selectState ? (
                      <div
                        className={selectState ? style.AllState : style.stateUp}
                      >
                        {allState.map((stateItem) => {
                          return (
                            <input
                              type="text"
                              name="state"
                              id="state"
                              readOnly
                              value={stateItem.state}
                              onClick={(e) => {
                                checkStateExits(stateItem, e);
                              }}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <input
                    type="text"
                    name=""
                    id=""
                    value={state}
                    placeholder="COUNTY"
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                  />
                )}
                <input
                  type="text"
                  value={user?.coutryname || ""}
                  readOnly
                  placeholder="Country"
                />
                {user.coutrycode === "44" ? (
                  <input
                    type="text"
                    name=""
                    id=""
                    value={zipcode}
                    placeholder="POSTCODE"
                    onChange={(e) => {
                      setZipCode(e.target.value);
                    }}
                  />
                ) : (
                  <input
                    type="text"
                    name=""
                    id=""
                    value={zipcode}
                    placeholder="ZIP/POSTAL CODE"
                    onChange={(e) => {
                      setZipCode(e.target.value);
                    }}
                  />
                )}

                <button
                  type="submit"
                  onClick={() => {
                    setIsAddress(false);
                    setAddressObj({
                      line1,
                      line2,
                      city,
                      state,
                      zipcode,
                    });
                    numberValidation();
                    console.log(addressObj);
                  }}
                  disabled={
                    line1 === "" ||
                    line2 === "" ||
                    city === "" ||
                    state === "" ||
                    zipcode === ""
                  }
                >
                  CONFIRM
                </button>
              </form>
            </div>
          ) : (
            ""
          )}
          {phonenumber ? (
            <div className={style.postpopup}>
              <div className={style.popupImage}>
                <img src={assets.winchaPopup} alt="" />
              </div>
              <div className={style.phnpopupText}>
                <p>Invalid Phone Number</p>
              </div>
              <div className={style.popupbutton}>
                <button
                  onClick={() => {
                    setPhonenumber(false);
                    setIsAddress(true);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          {postcodetrue ? (
            <div className={style.postpopup}>
              <div className={style.popupImage}>
                <img src={assets.winchaPopup} alt="" />
              </div>
              <div className={style.postpopupText}>
                <p>Post code invalid</p>
              </div>
              <div className={style.popupbutton}>
                <button
                  onClick={() => {
                    setPostcodeTrue(false);
                    setIsAddress(true);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          ) : (
            ""
          )}

          {accountSuspend ? (
            <>
              <div className={style.suspendOverlay}></div>
              <div className={style.popupContent}>
                <div className={style.popupImage}>
                  <img src={assets.winchaPopup} alt="" />
                </div>
                <div className={style.resendpopupText}>
                  <p>Your account was suspended</p>
                </div>
                <div className={style.popupButton}>
                  <button
                    onClick={(e) => {
                      console.log("clicked");
                      localStorage.removeItem("user");
                      localStorage.removeItem("SaveShipping");
                      localStorage.removeItem("times");
                      localStorage.removeItem("premium");
                      localStorage.removeItem("lastNotificationId");
                      localStorage.removeItem("userType");
                      localStorage.removeItem('iosPopupShown');
                      navigate("/login")
                      window.location.reload();
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            </>

          ) : ("")}

          {userExist ? (
            <>
              <div className={style.suspendOverlay}></div>
              <div className={style.popupContent}>
                <div className={style.popupImage}>
                  <img src={assets.winchaPopup} alt="" />
                </div>
                <div className={style.resendpopupText}>
                  <p>User does not exist</p>
                </div>
                <div className={style.popupButton}>
                  <button
                    onClick={(e) => {
                      console.log("clicked");
                      localStorage.removeItem("user");
                      localStorage.removeItem("SaveShipping");
                      localStorage.removeItem("times");
                      localStorage.removeItem("premium");
                      localStorage.removeItem("lastNotificationId");
                      localStorage.removeItem("userType");
                      localStorage.removeItem('iosPopupShown');
                      navigate("/login")
                      window.location.reload();
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            </>

          ) : ("")}

          {checkError ? (
            <div className={style.postpopup}>
              <div className={style.popupImage}>
                <img src={assets.winchaPopup} alt="" />
              </div>
              <div className={style.postpopupText}>
                <p>State is not available for shipping</p>
              </div>
              <div className={style.popupbutton}>
                <button
                  onClick={() => {
                    setPostcodeTrue(false);
                    setIsAddress(true);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          ) :
            ("")}
          {/* {loading?
          <Lottie animationData={AllAnimation.Loader}/>
          :""} */}
          <div className={style.ProfileContent}>
            <div className={style.ProfileImage}>
              <img src={profile} alt="" />
            </div>
            <div className={style.ProfileAction}>
              {/* <div className={style.AllBtns}>
            <button className={style.btn2}>SHIPPING</button>
            <button className={style.btn1}>MY DETAILS</button>
          </div> */}
              {/* <button className={style.ProfileBtn}>PROFILE</button>
            <button className={style.history}>HISTORY</button>
            <button className={style.Faq}>FAQ</button> */}
              {userId !== null && user && user.username !== "" ? (
                <button
                  className={style.Deactivate}
                  onClick={() => {
                    if (userId !== null && user && user.username !== "") {
                      setDeactivate(true);
                    }
                  }}
                >
                  Delete ACCOUNT
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={style.FullDetails}>
            <div className={style.TabBtns}>
              {/* mydetails */}

              {myDetails === false ? (
                <img
                  src={myDetailsGray}
                  alt=""
                  className={myDetails ? style.TabBtnActiveImg : style.TabImg}
                  onClick={() => {
                    setmyDetails(false);
                    console.log("from mydetails", myDetails);
                  }}
                />
              ) : (
                <img
                  src={myDetailsBlue}
                  alt=""
                  className={myDetails ? style.TabBtnActiveImg : style.TabImg}
                  onClick={() => {
                    setmyDetails(false);
                    console.log("from mydetails", myDetails);
                  }}
                />
              )}
              {/* shipping */}
              {myDetails === false ? (
                <img
                  src={shippingBlue}
                  alt=""
                  className={myDetails ? style.TabImg : style.TabBtnActiveImg}
                  onClick={() => {
                    setmyDetails(true);
                    console.log("from shipping", myDetails);
                  }}
                />
              ) : (
                <img
                  src={shippingGray}
                  alt=""
                  className={myDetails ? style.TabImg : style.TabBtnActiveImg}
                  onClick={() => {
                    setmyDetails(true);
                    console.log("from shipping", myDetails);
                  }}
                />
              )}
            </div>

            <div className={style.TabSection}>
              {myDetails === false ? (
                <div className={style.Shipping}>
                  <div className={style.Phone}>
                    <div className={style.title}>
                      <p>Phone Number</p>
                    </div>
                    <div className={style.InputSection}>
                      <input
                        // className={style.phoneInputSection}
                        type="text"
                        value={user?.phone}
                        placeholder="Please Enter"
                        readOnly
                        onClick={handleInputClick}
                        className={
                          user && user.username
                            ? style.loggedIn
                            : style.loggedOut
                        }
                      />
                      <img
                        src={rightArrow}
                        onClick={() => {
                          setIsAddress(true);
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                  <hr color="#000" className={style.hrLine} />
                  <div className={style.Address}>
                    <div className={style.title}>
                      <p>Shipping</p>
                    </div>
                    <div className={style.InputSection}>
                      {/* <input
                      type="text"
                      value={user?.username || ""}
                      readOnly
                      placeholder="Username"
                    /> */}

                      <img
                        src={rightArrow}
                        onClick={() => {
                          setIsAddress(true);
                        }}
                        alt=""
                      />

                      <input
                        type="text"
                        value={user?.addressline1 || ""}
                        readOnly
                        placeholder="Please Enter"
                        onClick={handleInputClick}
                        className={
                          user && user.username
                            ? style.loggedIn
                            : style.loggedOut
                        }
                      />
                      <input
                        type="text"
                        value={user?.addressline2 || ""}
                        readOnly
                        placeholder=""
                        className={style.loggedIn}
                      />
                      <input
                        type="text"
                        value={user?.city || ""}
                        readOnly
                        placeholder=""
                        className={style.loggedIn}
                      />
                      {user && user.coutryname === "UK" ? (
                        <input
                          type="text"
                          value={user?.state || ""}
                          readOnly
                          placeholder=""
                          className={style.loggedIn}
                        />
                      ) : user && user.coutryname === "USA" ? (
                        <input
                          type="text"
                          value={user?.state || ""}
                          readOnly
                          placeholder=""
                          className={style.loggedIn}
                        />
                      ) : (
                        ""
                      )}
                      <input
                        type="text"
                        value={user?.coutryname || ""}
                        readOnly
                        placeholder=""
                        className={style.loggedIn}
                      />
                      {/* <input type="text"  value={user?.county||""}readOnly placeholder="Country"/> */}
                      {user && user.coutryname === "UK" ? (
                        <input
                          type="text"
                          value={user?.zipcode || ""}
                          readOnly
                          placeholder=""
                          className={style.loggedIn}
                        />
                      ) : user && user.coutryname === "USA" ? (
                        <input
                          type="text"
                          value={user?.zipcode || ""}
                          readOnly
                          placeholder=""
                          className={style.loggedIn}
                        />
                      ) : (
                        ""
                      )}
                      {/* <input type="text" value={user?.zipcode||""}readOnly placeholder="Zipcode"/> */}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={style.myDetails}>
                  <div className={style.Username}>
                    <div className={style.title}>
                      <p>Username</p>
                    </div>
                    <div className={style.InputSection}>
                      <input
                        type="text"
                        value={username}
                        readOnly
                        // placeholder="Your Name"
                        placeholder="Please Enter"
                        onClick={handleInputClick}
                        className={
                          user && user.username
                            ? style.loggedIn
                            : style.loggedOut
                        }
                      />
                    </div>
                  </div>
                  <div className={style.Birthday}>
                    <div className={style.title}>
                      <p>Birthday</p>
                    </div>
                    <div className={style.InputSection}>
                      <input
                        type="text"
                        value={birthday}
                        readOnly
                        // placeholder="DD/MM/YYYY"
                        placeholder="Please Enter"
                        onClick={handleInputClick}
                        className={
                          user && user.username
                            ? style.loggedIn
                            : style.loggedOut
                        }
                      />
                      {/* <input type="date" value={birthday} onChange/> */}
                    </div>
                  </div>
                  <div className={style.EmailAddress}>
                    <div className={style.title}>
                      <p>Email Address</p>
                    </div>
                    <div className={style.InputSection}>
                      <input
                        type="email"
                        value={email}
                        readOnly
                        // placeholder="YourName@gmail.com"
                        placeholder="Please Enter"
                        onClick={handleInputClick}
                        className={
                          user && user.username
                            ? style.loggedIn
                            : style.loggedOut
                        }
                      />
                      {/* <input type="email" value={email} onChange={(e)=>{
                                setEmail(e.target.value)
                            }}/> */}
                    </div>
                  </div>
                  <div className={style.Password}>
                    <div className={style.title}>
                      <p>Password</p>
                    </div>
                    <div className={style.InputSection}>
                      <input
                        type="text"
                        // value="***********"
                        value={user && user.username ? "***********" : ""}
                        readOnly
                        placeholder="Please Enter"
                        onClick={handleInputClick}
                        className={
                          user && user.username
                            ? style.loggedIn
                            : style.loggedOut
                        }
                      />
                      {/* <MdOutlineKeyboardArrowRight/> */}
                      <img
                        src={rightArrow}
                        alt=""
                        onClick={() => {
                          setUpdatePassword(true);
                        }}
                      />

                      {/* <input type="password" value={password} onChange={(e)=>{
                                setPassword(e.target.value)
                            }}/> */}
                    </div>
                  </div>
                  <div className={style.Subscription}>
                    <div className={style.title}>
                      <p>Subscription</p>
                    </div>
                    <div className={style.InputSection}>
                      <input
                        type="text"
                        value={subscription}
                        readOnly
                        className={style.loggedIn}
                      />
                      {(user && user.vip === false) || userId === null ? (
                        <img
                          src={shippingInfo}
                          alt=""
                          onClick={() => {
                            if (
                              user &&
                              user.profile_status === false &&
                              user &&
                              user.username !== "" &&
                              user.username !== undefined
                            ) {
                              setResendEmail(true);
                            } else {
                              setPremiumPopup(true);
                            }
                          }}
                        />
                      ) : (
                        ""
                      )}
                      {/* <input type="text" value={subscription} onChange={(e)=>{
                                setSubscription(e.target.value)
                            }}/> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
