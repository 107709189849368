import React, { useEffect, useRef, useState } from "react";
import { music } from "../../assests/Musics/allMusic";
import style from "./Audio.module.css";

function PlayAudio({ gameMusic, setGameMusic, gameSound, setGameSound }) {
  const [showPopup, setShowPopup] = useState(false); // State to show/hide popup
  const audioRefHome = useRef(null);

  const musicStatus = localStorage.getItem("music")
    ? localStorage.getItem("music")
    : localStorage.setItem("music", JSON.stringify(false));
  useEffect(() => {
    if (gameMusic === 1 || gameMusic === "1") {
      audioRefHome.current.muted = false;
      playAudioBg();
      console.log(
        (audioRefHome.current.muted = false),
        "first_background_music"
      );
    } else {
      audioRefHome.current.muted = true;
      console.log(
        (audioRefHome.current.muted = true),
        "second_background_music"
      );
    }
    console.log(gameMusic);
  }, [gameMusic]);
  useEffect(() => {
    if (gameMusic === 1 || gameMusic === "1") {
      audioRefHome.current.muted = false;
      playAudioBg();
      console.log(
        (audioRefHome.current.muted = false),
        "third_background_music"
      );
    }
  }, [gameMusic]);
  async function audioEnded(src) {
    if (musicStatus === "true") {
      audioRefHome.current.muted = false;
      audioRefHome.current.src = src;
      audioRefHome.current.play();
      console.log(
        (audioRefHome.current.muted = false),
        "fourth_background_music"
      );
    } else {
      audioRefHome.current.muted = true;
      console.log(
        (audioRefHome.current.muted = true),
        "fifth_background_music"
      );
    }
  }
  async function playAudioBg() {
    try {
      audioRefHome.current.src = music.Menu;
      await audioRefHome.current.play(); // Try to play the audio
    } catch (error) {
      if (error.name === "NotAllowedError") {
        setShowPopup(true); // Show popup if NotAllowedError occurs
      }
    }
  }

  function handleUserInteraction() {
    setShowPopup(false); // Hide the popup after interaction
    audioRefHome.current.play(); // Play the audio after user interaction
  }

  return (
    <div>
      <audio ref={audioRefHome} onEnded={audioEnded} loop playsInline></audio>
      {/* {showPopup && (
        <>
          <div className={style.overlay}>
            <div className={style.permissionContainer}>
              <p>This website required music element.</p>
              <button onClick={handleUserInteraction}>OK</button>
            </div>
          </div>
        </>
      )} */}
    </div>
  );
}

export default PlayAudio;
