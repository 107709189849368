import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import style from "./Cookie.module.css";
import { Link } from "react-router-dom";
import { initializeGoogleAnalytics } from "../GoogleAnalytics";

const Cookie = () => {
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const { configuration } = useSelector((state) => state.configuration);
  const consentCookie = localStorage.getItem("cookie_consent");
  const userType = localStorage.getItem("userType");

  // Get the current route using react-router's useLocation hook
  const location = useLocation();
  const isOnHomePage = location.pathname === "/"; // Adjust the pathname as per your route configuration

  useEffect(() => {
    if (consentCookie === null) {
      setShowCookieBanner(isOnHomePage); // Show only on the home page for new users
    } else if (consentCookie === "false") {
      if (sessionStorage.getItem("rejectedInSession") !== "true") {
        setShowCookieBanner(isOnHomePage);
      }
    }
  }, [isOnHomePage, consentCookie]);

  // useEffect(() => {
  //   if (userType === "common_user" && consentCookie === "false") {
  //     if (sessionStorage.getItem("rejectedInSession") !== "true") {
  //       setShowCookieBanner(isOnHomePage);
  //     }
  //   }
  // }, [userType, isOnHomePage, consentCookie]);

  const handleAccept = () => {
    localStorage.setItem("cookie_consent", "true");
    setShowCookieBanner(false);
    initializeGoogleAnalytics();
  };

  const handleReject = () => {
    localStorage.setItem("cookie_consent", "false");
    sessionStorage.setItem("rejectedInSession", "true"); // Set flag for current session
    setShowCookieBanner(false);
  };

  if (!showCookieBanner) {
    return null;
  }

  return (
    <div className={style.second_container}>
      <div className={style.cooki_left}>
        <p>By using this site you agree to our</p>
        <span>
          <Link
            onClick={() => {
              window.open(`${configuration.privacy}`, "_blank");
            }}
          >
            cookie
          </Link>
        </span>
        <p>policy</p>
      </div>
      <div className={style.cooki_right}>
        <button onClick={handleReject}>Reject</button>
        <button onClick={handleAccept}>Accept</button>
      </div>
    </div>
  );
};

export default Cookie;
