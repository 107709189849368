import React, { useState } from "react";
import style from "./AccountDeletion.module.css";
import logo from "../../assests/Wincha Logo.png";
import eye from "../../assests/Password Eye.png";
import { baseUrl } from "../url";
import { useNavigate } from "react-router-dom";

function AccountDeletion() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [formShow, setFormShow] = useState(true);
  const [checkBoxValidation, setCheckBoxValidation] = useState(true);
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  async function userLogin() {
    await fetch(`${baseUrl}/user/account/email/login`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === "True") {
          setFormShow(false);
          setUserId(data.data.user_id);
          setToken(data.data.token);
        } else {
          setShowValidation(true);
          setValidationMessage(data.description);
        }
      });
  }

  async function deactivateAccount() {
    await fetch(`${baseUrl}/game/webapp/issue/report `, {
      method: "POST",
      body: JSON.stringify({
        playerID: userId,
        machineID: "",
        productID: "",
        title: "deactivation",
        content: "",
        source: "web",
      }),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === "True") {
          localStorage.removeItem("user");
          localStorage.removeItem("SaveShipping");
          localStorage.removeItem("times");
          localStorage.removeItem("premium");
          localStorage.removeItem("lastNotificationId");
          localStorage.removeItem("userType");
          localStorage.removeItem("iosPopupShown");
          navigate("/");
          window.location.reload();
        }
      });
  }

  return (
    <div className={style.container}>
      {formShow ? (
        <div className={style.msg_box}>
          <div className={style.deleteSection}>
            <div className={style.headerImage}>
              <img src={logo} alt="" />
            </div>
            <div className={style.inputSection}>
              <div className={style.inputHeader}>
                <h1>Account Deletion</h1>
              </div>
              <div className={style.inputDetail}>
                <div className={style.inputText}>
                  <p>
                    Please sign in below to delete your Wincha&reg; account.
                  </p>
                </div>
                <div className={style.inputEmail}>
                  <input
                    type="email"
                    name=""
                    id=""
                    placeholder="EMAIL"
                    value={email}
                    onClick={(e) => {
                      setValidationMessage("");
                      setShowValidation(false);
                    }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    autocomplete="off"                  />
                </div>

                <div className={style.passwordInput}>
                  <input
                    type={showPassword ? "text" : "password"}
                    name=""
                    id=""
                    placeholder="PASSWORD"
                    value={password}
                    onClick={(e) => {
                      setValidationMessage("");
                      setShowValidation(false);
                    }}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    autocomplete="off"
                  />

                  <div className={style.inputLogo}>
                    <img src={eye} alt="" onClick={togglePasswordVisibility} />
                  </div>
                </div>
                {showValidation ? (
                  <div className={style.validationMessage}>
                    <p>{validationMessage}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={style.inputButtons}>
                <button
                  onClick={() => {
                    userLogin();
                  }}
                >
                  Continue
                </button>
                <button
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={style.msg_box2}>
          <div className={style.deleteSectionConfirm}>
            <div className={style.headerImage}>
              <img src={logo} alt="" />
            </div>

            <div className={style.confirmSection}>
              <div className={style.delHeader}>
                <h1>Account Deletion</h1>
              </div>
              <div className={style.confirmInputText}>
                <p>
                  If you proceed with an account deletion, your account will be
                  irreversibly deleted resulting in your data and any remaining
                  credit being permanently and immediately erased. Please
                  remember to also turn off any in-app subscriptions in your
                  device account settings that are associated with this app.
                </p>
              </div>
              <div className={style.checkbox}>
                <input
                  type="checkbox"
                  name="myCheckbox"
                  value="checked"
                  onChange={() => {
                    setCheckBoxValidation(false);
                  }}
                ></input>
                <p>Please check the box to confirm</p>
              </div>
            </div>
            <div className={style.inputButtons2}>
              <button
                disabled={checkBoxValidation}
                onClick={() => {
                  deactivateAccount();
                }}
              >
                DELETE
              </button>
              <button
                onClick={() => {
                  navigate("/");
                }}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AccountDeletion;
