import image1 from '../assests/complete-page-1.png'
import image2 from '../assests/complete-page-2.png'
import image3 from '../assests/complete-page-3.png'
import image4 from '../assests/ABOUT SECTION - PAGE 4 v5 NEW.png'

// const Image = [
//     {
//         id:1,
//         "image"
//     }
// ]
const Image = [image1,image2,image3,image4]

export default Image