import React, { useEffect, useRef, useState } from "react";
import style from "./OrderConfirmed.module.css";
import { cartAction, notificationAction } from "../../actions/user";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../url";
import PlayAudio from "../Audio/PlayAudio";
import PlaySound from "../Audio/PlaySound";
import confirm from "../../assests/checkOut.png"
const OrderConfirmed = ({
  gameMusic,
  setGameMusic,
  gameSound,
  setGameSound,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userId =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  const token = JSON.parse(localStorage.getItem("token"));
  const [cartSuccess, setCartSUccess] = useState(false);
  const [playAudio, setPlayAudio] = useState("");

  function cartCheckOut(id) {
    fetch(`${baseUrl}/cart/v3/webapp/checkout `, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
        response_id: id,
      }),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === "True") {
          setCartSUccess(true);
        }
      });
  }

  function cartCheckOut2() {
    fetch(`${baseUrl}/cart/v3/webapp/checkout `, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === "True") {
          setCartSUccess(true);
        }
      });
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get("session_id");
    console.log(sessionId);

    if (sessionId) {
      cartCheckOut(sessionId);
    } else {
      cartCheckOut2();
    }
  }, [location]);

  useEffect(() => {
    dispatch(cartAction());
    dispatch(notificationAction());
    if (cartSuccess) {
      setTimeout(() => {
        navigate("/prizes");
      }, 8000);
    }
  }, [dispatch, cartSuccess]);

  return (
    <div className={style.Container}>
      <PlayAudio
        gameMusic={gameMusic}
        setGameMusic={setGameMusic}
        gameSound={gameSound}
        setGameSound={setGameSound}
      />
      {playAudio ? (
        <PlaySound
          setPlayAudio={setPlayAudio}
          src={playAudio}
          gameMusic={gameMusic}
          setGameMusic={setGameMusic}
          gameSound={gameSound}
          setGameSound={setGameSound}
        />
      ) : (
        ""
      )}
      <img src={confirm} alt="" />
    </div>
  );
};

export default OrderConfirmed;
