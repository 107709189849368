// ga.js
export const initializeGoogleAnalytics = () => {
  if (typeof window !== 'undefined') {
    window.gtag('config', 'G-SGIVRWZHR6', { 'anonymize_ip': true, 'cookie_flags': 'max-age=7200;secure;samesite=none', });
  }
};

export const trackEvent = (eventName, eventData) => {
  if (typeof window !== 'undefined') {
    window.gtag('event', eventName, eventData);
  }
};
