export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"

export const REGISTER_REQUEST = "REGISTER_REQUEST"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"

export const CHECK_USERNAME_REQUEST = "CHECK_USERNAME_REQUEST"
export const CHECK_USERNAME_SUCCESS = "CHECK_USERNAME_SUCCESS"
export const CHECK_USERNAME_FAIL = "CHECK_USERNAME_FAIL"

export const PROFILE_REQUEST = "PROFILE_REQUEST"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_FAIL = "PROFILE_FAIL"

export const NOTIFICATION_REQUEST = "NOTIFICATION_REQUEST"
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS"
export const NOTIFICATION_FAIL = "NOTIFICATION_FAIL"

export const CART_REQUEST = "CART_REQUEST"
export const CART_SUCCESS = "CART_SUCCESS"
export const CART_FAIL = "CART_FAIL"

