import React from "react";
import style from "./PasswordReset.module.css";
import info from "../../assests/Information Icon.png";
import eye from "../../assests/Password Eye.png";
import wincha from "../../assests/Wincha Pop-Up Icon.png";
import { useState } from "react";
import { baseUrl } from "../url";
import success from "../../assests/success2.png";

const PasswordReset = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  console.log(token);

  const [passIcon, setPassIcon] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordSection, setPasswordSection] = useState(true);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetFail, setResetFail] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);

  let pathname;
  console.log(window.location);
  const path = window.location.pathname;
  const splitPath = path.split("/");
  pathname = splitPath[3];
  console.log(path);
  console.log(splitPath[3]);
  console.log(pathname);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleToggleConfirmPassword = () => {
    setConfirmShowPassword((prevShowPassword) => !prevShowPassword);
  };

  async function updatePasswordFunc() {
    if (password.length < 8 || password.length > 20) {
      setError("Invalid password length. Please enter between 8 and 20 characters.");
    } else if (!/[A-Z]/.test(password)) {
      setError("Invalid password. Include at least 1 capital letter.");
    } else if (!/\d/.test(password)) {
      setError("Invalid password. Include at least 1 number.");
    } else if (!/(.*[!@#$%&*()'+,./:;<=>?^_`{|}-])/.test(password)) {
      setError("Invalid password. Include at least 1 special character.");
    } else if (!/^\S+$/.test(password)) {
      setError("Invalid password. No spaces allowed.");
    } else if (password === "") {
      setError("Password should not be empty.");
    } else if (password !== confirmPassword) {
      setError("Passwords do not match.");
    } else {
      await fetch(`${baseUrl}/user/webapp/reset/password`, {
        method: "PUT",
        body: JSON.stringify({
          id: pathname,
          password: password,
          source: "web",
        }),
        headers: {
          "Content-Type": "application/json",
          "access-token": `${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setPassword("");
          setConfirmPassword("");
          setError("");
          if (data.status === "True") {
            setPasswordSection(false);
            setResetSuccess(true);
            setResetFail(false);
          } else {
            setResetSuccess(false);
            setResetFail(true);
          }
        });
    }
  }

  return (
    <div className={style.Conatiner}>
      <div className={style.ResetPassword}>
        {passwordSection ? (
          <>
            <div className={style.password}>
              <div className={style.info}>
                <img
                  src={info}
                  alt=""
                  onClick={() => {
                    setPassIcon(true);
                  }}
                />
              </div>
              <input
                required
                placeholder="PASSWORD"
                value={password}
                className={style.input}
                onChange={handlePasswordChange}
                type={showPassword ? "text" : "password"}
                onClick={() => {
                  setError("");
                  setResetFail(false);
                }}
              />
              <img
                className={style.eyeIcon}
                src={eye}
                onClick={handleTogglePassword}
                alt="not available"
              />
            </div>

            <div className={style.password}>
              <input
                required
                placeholder="REPEAT PASSWORD"
                className={style.input}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                type={confirmShowPassword ? "text" : "password"}
                onClick={() => {
                  setError("");
                }}
              />

              <img
                className={style.eyeIcon}
                src={eye}
                onClick={handleToggleConfirmPassword}
                alt="not available"
              />
            </div>

            <button
              onClick={() => {
                updatePasswordFunc();
                console.log("clicked");
              }}
              className={style.formBtn}
            >
              Change Password
            </button>
            {error && <p className={style.criteriaCheck}>{error}</p>}
          </>
        ) : (
          ""
        )}

        {resetSuccess ? (
          <div className={style.Success}>
            <div className={style.successImage}>
              <img src={success} alt="" />
            </div>
            <div className={style.verification}>
              <p>Password Reset Success</p>
            </div>
          </div>
        ) : (
          ""
        )}
        {resetFail ? (
          <div className={style.Success}>
            <div className={style.verification}>
              <p>Password Reset Failed</p>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {passIcon ? (
        <div className={style.popup}>
          <div className={style.image}>
            <img src={wincha} alt="" />
          </div>
          <div className={style.content}>
            <ul>
              <p>Password must include:</p>
              <li>8-20 Characters</li>
              <li>At least 1 capital letter</li>
              <li>At least 1 number</li>
              <li>At least 1 special character</li>
              <li>No spaces</li>
            </ul>
          </div>
          <div className={style.action}>
            <button
              onClick={() => {
                setPassIcon(false);
              }}
            >
              OK
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PasswordReset;
