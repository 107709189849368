export const GET_ALL_PRODUCT_BY_COLLECTION_REQUEST = "GET_ALL_PRODUCT_BY_COLLECTION_REQUEST"
export const GET_ALL_PRODUCT_BY_COLLECTION_SUCCESS = "GET_ALL_PRODUCT_BY_COLLECTION_SUCCESS"
export const GET_ALL_PRODUCT_BY_COLLECTION_FAILED = "GET_ALL_PRODUCT_BY_COLLECTION_FAILED"

export const GAME_ENTRY_REQUEST = "GAME_ENTRY_REQUEST"
export const GAME_ENTRY_SUCCESS = "GAME_ENTRY_SUCCESS"
export const GAME_ENTRY_FAILED = "GAME_ENTRY_FAILED"

export const GET_ALL_GAMES_REQUEST = "GET_ALL_GAMES_REQUEST"
export const GET_ALL_GAMES_FAIL = "GET_ALL_GAMES_FAIL"
export const GET_ALL_GAMES_SUCCESS = "GET_ALL_GAMES_SUCCESS"

export const CONFIGURATION_SUCCESS = "CONFIGURATION_SUCCESS"
export const CONFIGURATION_FAILED = "CONFIGURATION_FAILED"
export const CONFIGURATION_REQUEST = "CONFIGURATION_REQUEST"