import React, { useEffect, useState } from "react";
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from "opentok-react";
import style from "./Screen.module.css";
// import { log } from '@opentok/client';
const OT = require("@opentok/client");
const Screen = ({ sessionId, token, setVideoGot, videoGot }) => {
  const [popup, setPopup] = useState(false);
  // console.log(sessionId,token)
  const [id, setId] = useState("");
  const data = {
    id: "",
    name: "",
    email: "",
    subscription: "",
    password: "",
  };
  useEffect(() => {
    setId(data.id);
  }, []);
  var apiKey = "47498471"; // Replace with your API key. See https://tokbox.com/account
  const session = OT.initSession(apiKey, sessionId);
  const reconnecting = session._.reconnecting();
  session.on("streamDestroyed", function (event) {
    console.log("Stream stopped. Reason: " + event.reason);

    setPopup(true);
  });

  return (
    <div className={style.container}>
      {videoGot ? (
        <OTSession apiKey={apiKey} sessionId={sessionId} token={token}>
          <OTStreams>
            <OTSubscriber
              onSubscribe={(e) => {
                console.log(e);
                setVideoGot(true);
                console.log("gotted");
              }}
              onError={(error) => {
                console.log("Exited", error);
              }}
            />
          </OTStreams>
        </OTSession>
      ) : (
        <OTSession apiKey={apiKey} sessionId={sessionId} token={token}>
          <OTStreams>
            <OTSubscriber
              onSubscribe={(e) => {
                console.log(e);
                setVideoGot(true);
                console.log("gotted");
              }}
              onError={(error) => {
                console.log("Exited", error);
              }}
            />
          </OTStreams>
        </OTSession>
      )}
    </div>
  );
};

export default Screen;
