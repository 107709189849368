import React, { useEffect, useState } from "react";
import style from "./AccountVerification.module.css";
import success from "../../assests/success2.png";
import { baseUrl } from "../url";
import NewLoader from "../NewLoader/NewLoader";
import verification from "../../assests/Account_Successfully_Created.png"



const AccountVerification = () => {

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);


  let pathname;
  console.log(window.location);
  const path = window.location.pathname;
  const splitPath = path.split("/");
  pathname = splitPath[3];
  console.log(path);
  console.log(splitPath[3]);
  console.log(pathname);

  async function accountVerification() {
    await fetch(`${baseUrl}/user/account/verify`, {
      method: "POST",
      body: JSON.stringify({
        user_id: pathname,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLoading(false)
        if (data.status === 200) {
          setSuccess(true);
        } else {
          setSuccess(false);
        }
      });
  }
  useEffect(() => {
    accountVerification();
  }, []);

  return (
    <div className={style.Container}>
      {/* <div className={style.verification}>
            <div className={style.Image}>
            <img src={success} alt="" />
            </div>
            <div className={style.Content}>
                <p>YOU ACCOUNT HAS BEEN SUCCESSFULLY CREATED</p>
            </div>
        </div> */}

      {loading ? (<NewLoader />) :
        <>
          {success ? (
            <div className={style.success}>
              <img src={verification} alt="" />
            </div>
          ) : (
            <div className={style.faild}>
              <h1>Sorry!!!.. Invalid User</h1>
            </div>
          )}
        </>
      }

    </div>
  );
};

export default AccountVerification;
