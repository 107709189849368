import Game from './wincha_game.wav'
import Menu from './wincha_menu.wav'
import Boing from './winchaboing.wav'
import Chime from './winchachime.wav'
import Click from './winchaclick.wav'
import CoinDrop from './winchacoindrop.wav'
import Pop from './winchapop.wav'
import Whoops from './winchawhoops.wav'
import Wincha from './winchawincha.wav'
import Woohoo from './winchawoohoo.wav'


export const music = {Game,Menu,Boing,Chime,Click,CoinDrop,Pop,Whoops,Wincha,Woohoo}